export const FB_USERS_COLLECTION_NAME = "users";
export const FB_ROOMS_COLLECTION_NAME = "rooms";
export const FB_RESERVATIONS_COLLECTION_NAME = "reservations";

export const FB_STORAGE_BUCKET_URL = "https://firebasestorage.googleapis.com/v0/b/ubytovna-9f1c2.appspot.com/o/"

export const ROUTER_HOME_PAGE_PATH = "/";
export const ROUTER_DASHBOARD_PAGE_PATH = "/dashboard";
export const ROUTER_DASHBOARD_ROOMS_PAGE_PATH = "/dashboard/rooms";
export const ROUTER_DASHBOARD_RESERVATIONS_PAGE_PATH = "/dashboard/reservations";
export const ROUTER_LOGIN_PAGE_PATH = "/login";
export const ROUTER_CONTACT_PAGE_PATH = "/contact";
export const ROUTER_RESERVATION_PAGE_PATH = "/reservation";
export const ROUTER_ACTION_PAGE_PATH = "/action";
export const ROUTER_POLICY_PAGE_PATH = "/policy";
export const ROUTER_RESERVATION_CONFIRMATION_PAGE_PATH = "/reservation/confirmation";
export const ROUTER_RESERVATION_VIEW_PAGE_PATH = "/reservation/view";

export const DUMMY_ROOM_FILE_NAME = ".roomFile";

export const EMAIL_JS_PUBLIC_KEY = "r3mfiybH2Q48hpn-T";
export const EMAIL_JS_SERVICE_ID = "service_w8yqoc3";
export const EMAIL_JS_RESERVATION_CREATED_TEMPLATE_ID = "template_6cj0pjo";
export const EMAIL_JS_RESERVATION_CONFIRMED_TEMPLATE_ID = "template_yxthpy6";

export const EMAIL_JS_PUBLIC_KEY_V2 = "hAOMR4w4I6ssvm-Ku";
export const EMAIL_JS_SERVICE_ID_V2 = "service_vk63t7l";
export const EMAIL_JS_OQY_CONFIRMATION_TEMPLATE_ID_V2 = "template_u2a19wp";

export const CZECH_DATE_PICKER_STRINGS = {
  days: ['Neděle', 'Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek', 'Sobota'],
  shortDays: ['Ne', 'Po', 'Út', 'St', 'Čt', 'Pá', 'So'],
  months: ['Leden', 'Únor', 'Březen', 'Duben', 'Květen', 'Červen', 'Červenec', 'Srpen', 'Září', 'Říjen', 'Listopad', 'Prosinec'],
  shortMonths: ['Led', 'Úno', 'Bře', 'Dub', 'Kvě', 'Čer', 'Čvc', 'Srp', 'Zář', 'Říj', 'Lis', 'Pro'],
  goToToday: 'Přejít na dnešek'
};