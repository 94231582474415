import React, { useContext, useState } from 'react';
import MainMenu from '../../components/mainMenu/MainMenu';
import Footer from '../../components/footer/Footer';
import { IReservation, IReservationData } from '../../interfaces/interface';
import { FaBed } from 'react-icons/fa';
import './ReservationConfirmationPage.css';
import { Input, InputOnChangeData, Label, Textarea, useId } from '@fluentui/react-components';
import { addDoc, collection } from 'firebase/firestore';
import { AppContext } from '../..';
import { FB_RESERVATIONS_COLLECTION_NAME, ROUTER_POLICY_PAGE_PATH, ROUTER_RESERVATION_VIEW_PAGE_PATH } from '../../helpers/constants';
import { redirectToUrl, sendReservationCreatedEmail, sendReservationCreatedOgyEmail } from '../../helpers/functions';

export default function ReservationConfirmationPage(): JSX.Element {
  const ctx = useContext(AppContext);
  
  const reservation: IReservation = JSON.parse(localStorage.getItem("reservation") as string);

  const firstNameId = useId("input-first-name");
  const lastNameId = useId("input-last-name");
  const phoneNumberId = useId("input-phone-number");
  const emailId = useId("input-email");
  const organizationId = useId("input-organization");
  const streetId = useId("input-street");
  const pscId = useId("input-psc");
  const cityId = useId("input-city");
  const icoId = useId("input-ico");
  const noteId = useId("input-note");

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [organization, setOrganization] = useState<string>("");
  const [street, setStreet] = useState<string>("");
  const [psc, setPsc] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [ico, setIco] = useState<string>("");
  const [note, setNote] = useState<string>("");

  const sendReservationIsDisabled = !firstName || !lastName || !phoneNumber || !email || !street || !psc || !city;

  const onFirstNameChange = (ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setFirstName(data.value || "");
  };

  const onLastNameChange = (ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setLastName(data.value || "");
  };

  const onPhoneNumberChange = (ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setPhoneNumber(data.value || "");
  };

  const onEmailChange = (ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setEmail(data.value || "");
  };

  const onOrganizationChange = (ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setOrganization(data.value || "");
  };

  const onStreetChange = (ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setStreet(data.value || "");
  };

  const onPscChange = (ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setPsc(data.value || "");
  };

  const onCityChange = (ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setCity(data.value || "");
  };

  const onIcoChange = (ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setIco(data.value || "");
  };

  const onNoteChange = (ev: React.ChangeEvent<HTMLTextAreaElement>, data: InputOnChangeData) => {
    setNote(data.value || "");
  };

  const sendReservation = async () => {
    const reservationData: IReservationData = {
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      email: email,
      organization: organization,
      street: street,
      psc: psc,
      city: city,
      ico: ico,
      note: note,
      reservation: JSON.stringify(reservation),
      confirmation: false
    };

    try {
      const response = await addDoc(collection(ctx.db, FB_RESERVATIONS_COLLECTION_NAME), reservationData);
      console.log("Data uploaded", response.id, reservationData)
      localStorage.removeItem("reservation");
      await sendReservationCreatedEmail(email, response.id);
      await sendReservationCreatedOgyEmail();
      redirectToUrl(`${ROUTER_RESERVATION_VIEW_PAGE_PATH}?id=${response.id}`);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <div className="homePage">
      <MainMenu />

      <div className="homePageTextBlock">
        <span className="h1Title">DETAIL <strong>POPTÁVKY</strong></span>

        <div className="reservation">
          <div className="reservations">
            <div className='reservationHeader'>
              <span className="roomTitle">Vaše poptávka</span>
              <span>{new Date(reservation.arrivalDate).toLocaleDateString('cs-CZ')} - {new Date(reservation.departureDate).toLocaleDateString('cs-CZ')}</span>
            </div>
            {reservation.rooms.map((room, index) => {
              return (
                <div key={index} className="reservationRoom">
                  <div>
                    <span>{room.title}</span>
                    <FaBed size={18} />
                    <span>{room.numberOfBeds}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="reservationForm">
          <span className="roomTitle">Kontaktní údaje</span>
          <div className="formRowRows">
            <div className="formRow">
              <Label htmlFor={firstNameId}>Jméno *</Label>
              <Input type="text" id={firstNameId} onChange={onFirstNameChange} />
            </div>
            <div className="formRow">
              <Label htmlFor={lastNameId}>Příjmení *</Label>
              <Input type="text" id={lastNameId} onChange={onLastNameChange} />
            </div>
          </div>
          <div className="formRowRows">
            <div className="formRow">
              <Label htmlFor={phoneNumberId}>Telefonní číslo *</Label>
              <Input type="tel" id={phoneNumberId} onChange={onPhoneNumberChange} />
            </div>
            <div className="formRow">
              <Label htmlFor={emailId}>E-mail *</Label>
              <Input type="email" id={emailId} onChange={onEmailChange} />
            </div>
          </div>

          <span className="roomTitle">Doplňující informace</span>
          <div className="formRow">
            <Label htmlFor={noteId}>Poznámky a požadavky na sportoviště</Label>
            <Textarea id={noteId} rows={4} onChange={onNoteChange} />
          </div>

          <span className="roomTitle">Fakturační údaje</span>
          <div className="formRowRows">
            <div className="formRow">
              <Label htmlFor={organizationId}>Jméno / Organizace</Label>
              <Input type="text" id={organizationId} onChange={onOrganizationChange} />
            </div>
            <div className="formRow">
              <Label htmlFor={icoId}>Ič</Label>
              <Input type="text" id={icoId} onChange={onIcoChange} />
            </div>
          </div>
          <div className="formRow">
            <Label htmlFor={streetId}>Ulice a ČP *</Label>
            <Input type="text" id={streetId} onChange={onStreetChange} />
          </div>
          <div className="formRowRows">
            <div className="formRow">
              <Label htmlFor={pscId}>PSČ *</Label>
              <Input type="text" id={pscId} onChange={onPscChange} />
            </div>
            <div className="formRow">
              <Label htmlFor={cityId}>Město *</Label>
              <Input type="text" id={cityId} onChange={onCityChange} />
            </div>
          </div>

          <div className="reservationFormFooter">
            <span>* povinné pole</span>
            <span>Odesláním této rezervace souhlasíte se <a href='/policy' onClick={() => redirectToUrl(ROUTER_POLICY_PAGE_PATH)}>zpracováním osobních údajů</a>.</span>
            <div>
              <button className="reservationButton myButton" onClick={() => sendReservation()} disabled={sendReservationIsDisabled}>Odeslat poptávku</button>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
