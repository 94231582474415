import React, { useContext, useEffect, useState } from 'react';
import './ReservationViewPage.css';
import MainMenu from '../../components/mainMenu/MainMenu';
import Footer from '../../components/footer/Footer';
import { getParametrFromUrl } from '../../helpers/functions';
import { AppContext } from '../..';
import { collection, getDocs } from 'firebase/firestore';
import { FB_RESERVATIONS_COLLECTION_NAME } from '../../helpers/constants';
import { IReservation } from '../../interfaces/interface';
import { FaBed } from 'react-icons/fa';
import { Input, InputOnChangeData, Label, Textarea, useId } from '@fluentui/react-components';

export default function ReservationViewPage(): JSX.Element {
  const ctx = useContext(AppContext);

  const firstNameId = useId("input-first-name");
  const lastNameId = useId("input-last-name");
  const phoneNumberId = useId("input-phone-number");
  const emailId = useId("input-email");
  const organizationId = useId("input-organization");
  const streetId = useId("input-street");
  const pscId = useId("input-psc");
  const cityId = useId("input-city");
  const icoId = useId("input-ico");
  const noteId = useId("input-note");

  const [reservation, setReservation] = useState<IReservation>({} as IReservation);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [organization, setOrganization] = useState<string>("");
  const [street, setStreet] = useState<string>("");
  const [psc, setPsc] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [ico, setIco] = useState<string>("");
  const [note, setNote] = useState<string>("");
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);

  useEffect(() => {
    const reservationId = getParametrFromUrl("id");

    const fetchData = async (): Promise<void> => {
      const fbReservations = await getDocs(collection(ctx.db, FB_RESERVATIONS_COLLECTION_NAME));
      const fbReservation = fbReservations.docs.filter((doc) => doc.id === reservationId)[0].data();
      
      console.log(fbReservation);

      setReservation(JSON.parse(fbReservation.reservation));
      setFirstName(fbReservation.firstName);
      setLastName(fbReservation.lastName);
      setPhoneNumber(fbReservation.phoneNumber);
      setEmail(fbReservation.email);
      setOrganization(fbReservation.organization);
      setStreet(fbReservation.street);
      setPsc(fbReservation.psc);
      setCity(fbReservation.city);
      setIco(fbReservation.ico);
      setNote(fbReservation.note);
      setIsConfirmed(fbReservation.confirmation);
    };

    fetchData().catch((e) => console.error(e));
  }, [ctx.db]);

  const onFirstNameChange = (ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setFirstName(data.value || "");
  };

  const onLastNameChange = (ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setLastName(data.value || "");
  };

  const onPhoneNumberChange = (ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setPhoneNumber(data.value || "");
  };

  const onEmailChange = (ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setEmail(data.value || "");
  };

  const onOrganizationChange = (ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setOrganization(data.value || "");
  };

  const onStreetChange = (ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setStreet(data.value || "");
  };

  const onPscChange = (ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setPsc(data.value || "");
  };

  const onCityChange = (ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setCity(data.value || "");
  };

  const onIcoChange = (ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setIco(data.value || "");
  };

  const onNoteChange = (ev: React.ChangeEvent<HTMLTextAreaElement>, data: InputOnChangeData) => {
    setNote(data.value || "");
  };

  return (
    <div className="homePage">
      <MainMenu />

      <div className="homePageTextBlock">
        <span className="h1Title">VAŠE <strong>POPTÁVKA</strong></span>

        <div className="reservation">
          <div className="reservations">
            <div className='reservationHeader'>
              <span className="roomTitle">Vaše poptávka</span>
              <span>{new Date(reservation?.arrivalDate).toLocaleDateString('cs-CZ')} - {new Date(reservation.departureDate).toLocaleDateString('cs-CZ')}</span>
            </div>
            {isConfirmed && <span className="reservationConfirmedText">Vaše poptávka je potvrzená</span>}
            {reservation.rooms?.map((room, index) => {
              return (
                <div key={index} className="reservationRoom">
                  <div>
                    <span>{room.title}</span>
                    <FaBed size={18} />
                    <span>{room.numberOfBeds}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="reservationForm">
          <span className="roomTitle">Kontaktní údaje</span>
          <div className="formRowRows">
            <div className="formRow">
              <Label htmlFor={firstNameId}>Jméno *</Label>
              <Input type="text" id={firstNameId} value={firstName} onChange={onFirstNameChange} disabled />
            </div>
            <div className="formRow">
              <Label htmlFor={lastNameId}>Příjmení *</Label>
              <Input type="text" id={lastNameId} value={lastName} onChange={onLastNameChange} disabled />
            </div>
          </div>
          <div className="formRowRows">
            <div className="formRow">
              <Label htmlFor={phoneNumberId}>Telefonní číslo *</Label>
              <Input type="tel" id={phoneNumberId} value={phoneNumber} onChange={onPhoneNumberChange} disabled />
            </div>
            <div className="formRow">
              <Label htmlFor={emailId}>E-mail *</Label>
              <Input type="email" id={emailId} value={email} onChange={onEmailChange} disabled />
            </div>
          </div>

          <span className="roomTitle">Doplňující informace</span>
          <div className="formRow">
            <Label htmlFor={noteId}>Poznámky a požadavky na sportoviště</Label>
            <Textarea id={noteId} rows={4} value={note} onChange={onNoteChange} disabled />
          </div>

          <span className="roomTitle">Fakturační údaje</span>
          <div className="formRowRows">
            <div className="formRow">
              <Label htmlFor={organizationId}>Jméno / Organizace</Label>
              <Input type="text" id={organizationId} value={organization} onChange={onOrganizationChange} disabled />
            </div>
            <div className="formRow">
              <Label htmlFor={icoId}>Ič</Label>
              <Input type="text" id={icoId} value={ico} onChange={onIcoChange} disabled />
            </div>
          </div>
          <div className="formRow">
            <Label htmlFor={streetId}>Ulice a ČP *</Label>
            <Input type="text" id={streetId} value={street} onChange={onStreetChange} disabled />
          </div>
          <div className="formRowRows">
            <div className="formRow">
              <Label htmlFor={pscId}>PSČ *</Label>
              <Input type="text" id={pscId} value={psc} onChange={onPscChange} disabled />
            </div>
            <div className="formRow">
              <Label htmlFor={cityId}>Město *</Label>
              <Input type="text" id={cityId} value={city} onChange={onCityChange} disabled />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
