/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import './ContactPage.css';
import MainMenu from '../../components/mainMenu/MainMenu';
import Footer from '../../components/footer/Footer';

export default function ContactPage(): JSX.Element {
  return (
    <div className="homePage">
      <MainMenu />

      <div className="homePageTextBlock">
        <span className="h1Title">KONTAKTY</span>
        <p className="homePageText">Recepce včetně telefonů funguje <span className="strongText">od 8:00 do 19:00</span>. Po předchozí domluvě lze ubytovat i mimo tuto provozní dobu.</p>
        <p className="homePageText">Našich služeb pravidelně využívají Český atletický svaz, SG ŠAK Jablonec, Dukla Praha, Basket Slovanka, Aktis Praha, SKP Hvězda Pardubice a další.</p>
        <p className="homePageText">
          <span className="strongText">Kontakt pro soustředění</span> Petr Ogoun (<a href='tel:+420 723 772 956'>+420 723 772 956</a> / <a href='mailto:petr.ogoun@gmail.com'>petr.ogoun@gmail.com</a>)
        </p>

        <div className="contacts">
          <div className='contact'>
            <img className="contactsIcons" src={require('../../assets/icons/1608677_envelope_square_icon.png')} alt='Email icon' />
            <a href="mailto:atletikasb@gmail.com">atletikasb@gmail.com</a>
          </div>
          <div className='contact'>
            <img className="contactsIcons" src={require('../../assets/icons/1608789_phone_square_icon.png')} alt='Phone icon' />
            <a href="tel:+420 326 911 826">+420 326 911 826</a>
            <a href="tel:+420 721 644 927">+420 721 644 927</a>
          </div>
          <div className='contact'>
            <img className="contactsIcons" src={require('../../assets/icons/216242_home_icon.png')} alt='Home icon' />
            <a href='https://maps.app.goo.gl/SjhWS7M84hWtaoNP8' target='_blank' rel="noreferrer" style={{ textAlign: "center" }}>
              Houštka 375/3<br />
              250 02 Stará Boleslav
            </a>
          </div>
        </div>

        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1056.4677408676087!2d14.689128384785569!3d50.18815674477153!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470bf1a0b0a2aeb1%3A0xd4112f385f19704e!2sPenzion%20Atletika%20Star%C3%A1%20Boleslav%20-%20Hou%C5%A1tka!5e0!3m2!1scs!2scz!4v1713467481876!5m2!1scs!2scz"
          width="100%"
          height="300px"
          style={{ border: "0", paddingTop: "25px"}}
          allowFullScreen
          loading="lazy"
          referrerPolicy='no-referrer-when-downgrade'>
        </iframe>
      </div>

      <Footer />
    </div>
  );
}
