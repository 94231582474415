import React, { useContext, useEffect, useState } from 'react';
import './LoginPage.css';
import { Button, Input, InputOnChangeData, Label, MessageBar, MessageBarActions, MessageBarBody, MessageBarTitle, useId } from '@fluentui/react-components';
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { redirectToUrl } from '../../helpers/functions';
import { AppContext } from '../..';
import { ROUTER_DASHBOARD_PAGE_PATH, ROUTER_HOME_PAGE_PATH } from '../../helpers/constants';

export interface ILoginPageProps {
};

export default function LoginPage(props: ILoginPageProps): JSX.Element {
  const ctx = useContext(AppContext);

  const emailId = useId("input-email");
  const passwordId = useId("input-password");

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [userIsLoggedIn, setUserIsLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    onAuthStateChanged(ctx.auth, (user) => {
      if (user) {
        setUserIsLoggedIn(true);
      } else {
        setUserIsLoggedIn(false);
      }
    });
  }, [ctx.auth]);

  const onUsernameChange = (ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setEmail(data.value);
  };

  const onPasswordChange = (ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setPassword(data.value);
  };

  const onLoginClick = () => {
    signInWithEmailAndPassword(ctx.auth, email, password).then((userCredential) => {
      redirectToUrl(ROUTER_DASHBOARD_PAGE_PATH);
    }).catch((error) => {
      const errorMessage = error.message;
      console.error("🚀 ~ signInWithEmailAndPassword ~ errorMessage:", errorMessage);
    });
  };

  const onCancelClick = () => {
    redirectToUrl(ROUTER_HOME_PAGE_PATH);
  };

  const onAdminCenterClick = () => {
    redirectToUrl(ROUTER_DASHBOARD_PAGE_PATH);
  };

  const onLogoutClick = () => {
    signOut(ctx.auth).then(() => {
      redirectToUrl(ROUTER_HOME_PAGE_PATH);
    }).catch((error) => {
      console.error("🚀 ~ signOut ~ error", error);
    });
  };

  return (
    <div className="loginPage">
      {!userIsLoggedIn ? <div className="loginForm">
        <h1>UBYTOVNA HOUŠTKA</h1>
        <div className="formRow">
          <Label htmlFor={emailId}>E-mail</Label>
          <Input type="email" id={emailId} onChange={onUsernameChange} />
        </div>
        <div className="formRow">
          <Label htmlFor={passwordId}>Heslo</Label>
          <Input type="password" id={passwordId} onChange={onPasswordChange} />
        </div>
        <div className="formFooter">
          <Button appearance="secondary" onClick={onCancelClick}>Zrušit</Button>
          <Button appearance="primary" onClick={onLoginClick}>Přihlásit se</Button>
        </div>
      </div> : <div className="loginForm">
        <h1>UBYTOVNA HOUŠTKA</h1>
        <MessageBar intent="success">
          <MessageBarBody>
            <MessageBarTitle>Jste přihlášen</MessageBarTitle>
            Odhlašte se, nebo přejděte do správy ubytovny.
          </MessageBarBody>
          <MessageBarActions>
            <Button appearance="primary" onClick={onAdminCenterClick}>Správa ubytovny</Button>
            <Button appearance="secondary" onClick={onLogoutClick}>Odhlásit se</Button>
          </MessageBarActions>
        </MessageBar>
      </div>}
    </div>
  );
}
