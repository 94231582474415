import * as React from 'react';
import { useState } from 'react';
import './HomePage.css';
import MainMenu from '../../components/mainMenu/MainMenu';
import Footer from '../../components/footer/Footer';
import { Dialog, DialogType } from '@fluentui/react';
import { MdOutlineCancel } from "react-icons/md";

const imgOval = require('../../assets/images/popupImages/LukasImage_20240807_105537.jpg');
const imgLouka = require('../../assets/images/popupImages/LukasImage_20240807_105657.jpg');
const imgHriste = require('../../assets/images/popupImages/LukasImage_20240807_105721.jpg');
const imgTunel = require('../../assets/images/popupImages/LukasImage_20240807_110021.jpg');
const imgPosilovna = require('../../assets/images/popupImages/LukasImage_20240807_110112.jpg');
const imgGril = require('../../assets/images/popupImages/LukasImage_20240807_110311.jpg');

export default function HomePage(): JSX.Element {
  const [hideDialog, setHideDialog] = useState<boolean>(true);
  const [dialogImage, setDialogImage] = useState<any | undefined>();
  const [dialogTitle, setDialogTitle] = useState<string>('');

  const closeDialog = (): void => {
    setHideDialog(true);
    setDialogImage(undefined);
    setDialogTitle('');
  };

  const openDialog = (dialogImage: any, dialogTitle: string): void => {
    setHideDialog(false);
    setDialogImage(dialogImage);
    setDialogTitle(dialogTitle);
  };

  return (
    <div className="homePage">
      <MainMenu />

      <div className="homePageTextBlock">
        <span className="h1Title">PENZION <strong>HOUŠTKA</strong></span>
        <p className="homePageText">Penzion nabízí ubytování v klidném prostředí houšteckého lesoparku v areálu Atletického stadionu v Houštce. Areál je obzvlášť vhodný pro sportovní kluby a školní výlety, turisté ocení množství památek v okolí, cyklisté kvalitu cyklostezek ve středním Polabí.</p>
      </div>
      <img className="homePageImage" src={require('../../assets/images/img-0.jpg')} alt='Houštka ubytovna' />
      <div className="homePageTextBlock">
        <span className="h1Title">VYBAVENÍ <strong>AREÁLU</strong></span>
        <div className="homePageValues">
          <p className="homePageText" style={{ cursor: "pointer" }} onClick={() => { openDialog(imgOval, 'Atletický ovál') }}>Atletický ovál</p>
          <p className="homePageText" style={{ cursor: "pointer" }} onClick={() => { openDialog(imgLouka, 'Vrhačská louka') }}>Vrhačská louka</p>
          <p className="homePageText" style={{ cursor: "pointer" }} onClick={() => { openDialog(imgGril, 'Venkovní gril') }}>Venkovní gril</p>
        </div>
        <div className="homePageValues">
          <p className="homePageText" style={{ cursor: "pointer" }} onClick={() => { openDialog(imgHriste, 'Víceúčelové hřiště') }}>Víceúčelové hřiště</p>
          <p className="homePageText" style={{ cursor: "pointer" }} onClick={() => { openDialog(imgPosilovna, 'Posilovna') }}>Posilovna</p>
          <p className="homePageText" style={{ cursor: "pointer" }} onClick={() => { openDialog(imgTunel, 'Atletický tunel') }}>Atletický tunel</p>
        </div>
      </div>
      <img className="homePageImage" src={require('../../assets/images/img-1.jpg')} alt='Atletický stadión' />
      <div className="homePageTextBlock">
        <span className="h1Title">POSKYTOVANÉ <strong>SLUŽBY</strong></span>
        <p className="homePageText">
Nabízíme pronájem sportovišť s kompletním vybavením a příjemnými bonusy. K dispozici je wi-fi a parkování přímo v areálu, stejně jako ohniště pro posezení. Pro sportovce máme atletické vybavení a úschovnu kol. Organizujeme závody a turnaje v různých sportech. Pro firmy poskytujeme prostor až pro 40 osob v zasedací místnosti. Ceny jsou přizpůsobeny počtu osob a využití zařízení.</p>
      </div>

      <Dialog
          hidden={hideDialog}
          onDismiss={closeDialog}
          dialogContentProps={{
            type: DialogType.normal,
            styles: { header: { display: 'none' }, inner: { padding: 0 } },
          }}
          modalProps={{
            isBlocking: false,
            styles: { main: { maxWidth: "1000px !important", width: "60%", backgroundColor: "transparent", boxShadow: "none" }, layer: { backdropFilter: "blur(5px)" } },
            closeButtonAriaLabel: 'Close',
          }}
        >
          <div className="dialogCustomHeader">
            <span className="homePageText">{dialogTitle}</span>
            <MdOutlineCancel className="dialogCloseIcon" onClick={closeDialog} />
          </div>
          {dialogImage && <img src={dialogImage} alt={dialogTitle} width="100%" loading='lazy' />}
      </Dialog>

      <Footer />
    </div>
  );
}
