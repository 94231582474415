import React, { useContext, useEffect, useState } from 'react';
import { IFbUser } from '../../interfaces/interface';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { redirectToUrl } from '../../helpers/functions';
import { collection, getDocs } from 'firebase/firestore';
import "./AdminPage.css";
import { PrimaryButton, Stack } from '@fluentui/react';
import Rooms from './adminPanels/Rooms';
import { AppContext } from '../..';
import { FB_USERS_COLLECTION_NAME, ROUTER_DASHBOARD_RESERVATIONS_PAGE_PATH, ROUTER_DASHBOARD_ROOMS_PAGE_PATH, ROUTER_HOME_PAGE_PATH, ROUTER_LOGIN_PAGE_PATH } from '../../helpers/constants';
import { Persona } from '@fluentui/react-components';
import { TDashboardPageType } from '../../helpers/types';
import Reservations from './adminPanels/Reservations';

export interface IAdminPageProps {
  panel?: TDashboardPageType;
};

export default function AdminPage(props: IAdminPageProps): JSX.Element {
  const ctx = useContext(AppContext);

  const {
    panel
  } = props;

  const [currentUserUID, setCurrentUserUID] = useState<string>("");
  const [allUsers, setAllUsers] = useState<IFbUser[]>([]);

  const currentUser = allUsers.find((user) => user.userId === currentUserUID);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const fbAllUsers = await getDocs(collection(ctx.db, FB_USERS_COLLECTION_NAME));

      setAllUsers(fbAllUsers.docs.map((doc) => { return doc.data() as IFbUser; }));
    };
    
    onAuthStateChanged(ctx.auth, (user) => {
      if (user) {
        setCurrentUserUID(user.uid);

        fetchData().catch((e) => console.error(e));
      } else {
        redirectToUrl(ROUTER_LOGIN_PAGE_PATH);
      }
    });
  }, [ctx.auth, ctx.db]);

  const onLogoutClick = () => {
    signOut(ctx.auth).then(() => {
      redirectToUrl(ROUTER_HOME_PAGE_PATH);
    }).catch((error) => {
      console.error("🚀 ~ signOut ~ error", error);
    });
  };

  return (
    <div className="pageDivAdmin">
      <div className="adminPanel">
        <span className="title">PENZION <strong>HOUŠTKA</strong></span>
        <Stack horizontal={false} gap={10}>
          <a href={ROUTER_DASHBOARD_ROOMS_PAGE_PATH} className="menuLinksStyle">POKOJE</a>
          <a href={ROUTER_DASHBOARD_RESERVATIONS_PAGE_PATH} className="menuLinksStyle">POPTÁVKY</a>
        </Stack>
        <br />
        <Stack horizontal={false} gap={10}>
          <Persona name={currentUser?.fullName} secondaryText={currentUser?.role} />
          <PrimaryButton text="Odhlásit se" onClick={onLogoutClick} />
        </Stack>
      </div>
      <div className="adminPageContainer">
        {(panel === 'rooms') && <Rooms />}
        {(panel === 'reservations') && <Reservations />}
      </div>
    </div>
  );
}
