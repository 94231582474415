import React from 'react';
import './Footer.css';

export default function Footer(): JSX.Element {
  return (
    <div className="homePageFooter">
      <span>Copyright © 2024 <a href="https://www.houstka.com" target='_blank' rel="noreferrer">Atletika Stará Boleslav</a></span>
      <span>Created by <a href="https://lukaspodolak.cz" target='_blank' rel="noreferrer">Lukáš Podolák</a></span>
    </div>
  );
}
