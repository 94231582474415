import * as React from 'react';
import MainMenu from '../../components/mainMenu/MainMenu';
import Footer from '../../components/footer/Footer';

export default function ActionPage(): JSX.Element {
  return (
    <div className="homePage">
      <MainMenu />

      <div className="homePageTextBlock">
        <span className="h1Title">SOUKROMÉ I FIREMNÍ <strong>AKCE</strong></span>
        <p className="homePageText">Sportovní areál Houštka je ideálním místem pro pořádání soukromých i firemních akcí. Nabízíme široké sportovní zázemí a možnost ubytování, což naše prostory činí skvělými pro oslavy, sportovní soustředění, teambuildingy a firemní školení. Naše moderně vybavené sportoviště a pohodlné ubytovací kapacity poskytují perfektní kombinaci pro aktivní odpočinek a produktivní setkání. Přestože nezajišťujeme cateringové služby, hosté mohou využít plně vybavené kuchyňky pro vlastní stravování nebo se spolehnout na místní restaurace v blízkosti areálu. Sportovní areál Houštka se nachází v klidném prostředí, což zaručuje příjemnou atmosféru a dostatek soukromí pro všechny účastníky.</p>
      </div>
      <img className="homePageImage" src={require('../../assets/images/img-2.jpg')} alt='Stan' />
      <Footer />
    </div>
  );
}
